.form-container {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.form-container .cell {
	margin:0 20px 20px 0;
}
.form-container .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.form-container .cell-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.form-container .cell-oneline .cell {
	width:200px;
}
.map {
	position:relative;
	width:100%;
	height:400px;
	background:#fff url('./Images/loading.svg') 50% 50% no-repeat;
}